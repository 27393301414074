import React from "react";
import Hero from "../components/Hero";
import LeadContact from "../components/LeadContact";
import Layout from "../components/Layout";

function Contact() {
  return (
    <Layout>
      <Hero>
        <div className="row">
          <div className="col-12">
            <h1 className="hero-title">Have an idea?</h1>
            <p className="hero-title">
              Tell us about it{" "}
              <a className="highlighted-link" href="mailto:bek@thebay.city">
                bek@thebay.city
              </a>
            </p>
          </div>
        </div>
      </Hero>
      <LeadContact />
    </Layout>
  );
}

export default Contact;
