import React from 'react'
import ReactDOM from 'react-dom'
import WithRouterApp from './App'
import {BrowserRouter as Router} from 'react-router-dom'

import './index.scss'
import {ThemeContextProvider} from './context/themeContext'

ReactDOM.render(
  <ThemeContextProvider>
    <Router>
      <WithRouterApp />
    </Router>
  </ThemeContextProvider>,
  document.getElementById('root')
)
