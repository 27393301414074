import React from "react";
function LeadTechStack() {
  return (
    <section
      className="section lead-tech-stack text-white"
      style={{
        backgroundImage: "url(/assets/images/tech-bg.webp)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="section-lead">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-4">
              <h3 className="lead-title anim-bot mb-4 mb-lg-0">
                <span className="text-muted">03.</span>
                <br />
                Technologies we craft with
              </h3>
            </div>
            <div className="col-12 col-lg-8 mt-0 mt-lg-4">
              <div className="row lead-content">
                <div className="col-12 col-md-4">
                  <div className="anim-bot">
                    <div className="tech-item">
                      <img
                        src="assets/images/reactjs.svg"
                        alt="ReactJS Frontend Development"
                      />
                      <span className="lead-small">ReactJS</span>
                    </div>
                    <div className="tech-item">
                      <img
                        src="assets/images/nextjs.svg"
                        alt="NextJS Frontend Development"
                      />
                      <span className="lead-small">NextJS</span>
                    </div>
                    <div className="tech-item">
                      <img
                        src="assets/images/shopify.svg"
                        alt="Shopify Development"
                      />
                      <span className="lead-small">Shopify</span>
                    </div>
                    <div className="tech-item">
                      <img
                        src="assets/images/googlecloud.svg"
                        alt="Google Cloud Development"
                      />
                      <span className="lead-small">Google Cloud</span>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="anim-bot">
                    <div className="tech-item">
                      <img
                        src="assets/images/reactjs.svg"
                        alt="React Native Mobile Development"
                      />
                      <span className="lead-small">React Native</span>
                    </div>
                    <div className="tech-item">
                      <img
                        src="assets/images/golang.svg"
                        alt="GoLang Backend Development"
                      />
                      <span className="lead-small">GoLang</span>
                    </div>
                    <div className="tech-item">
                      <img
                        src="assets/images/nodejs.svg"
                        alt="NodeJS Backend Development"
                      />
                      <span className="lead-small">NodeJS</span>
                    </div>
                    <div className="tech-item">
                      <img
                        src="assets/images/aws.svg"
                        alt="Amazon Web Services Development"
                      />
                      <span className="lead-small">Amazon Web Services</span>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="anim-bot">
                    <div className="tech-item">
                      <img
                        src="assets/images/flutter.svg"
                        alt="Flutter Mobile Development"
                      />
                      <span className="lead-small">Flutter</span>
                    </div>
                    <div className="tech-item">
                      <img
                        src="assets/images/graphql.svg"
                        alt="GraphQL Development"
                      />
                      <span className="lead-small">GraphQL</span>
                    </div>
                    <div className="tech-item">
                      <img src="assets/images/docker.svg" alt="Docker" />
                      <span className="lead-small">Docker</span>
                    </div>
                    <div className="tech-item">
                      <img
                        src="assets/images/java.svg"
                        alt="Java Development"
                      />
                      <span className="lead-small">Java</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LeadTechStack;
