import React from "react";
import Hero from "../components/Hero";
import LeadTeam from "../components/LeadTeam";
import ToContact from "../components/ToContact";
import Layout from "../components/Layout";

function About() {
  return (
    <Layout>
      <Hero>
        <div className="row">
          <div className="col-12">
            <h1 className="hero-title">Strategy, Creativity and Engineering</h1>
            <p className="hero-title">From the Bay — Worldwide!</p>
          </div>
        </div>
      </Hero>
      <LeadTeam />
      <ToContact />
    </Layout>
  );
}

export default About;
