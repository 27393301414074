import React from "react";
import Hero from "../components/Hero";
import LeadText from "../components/LeadText";
import LeadServices from "../components/LeadServices";
import LeadTechStack from "../components/LeadTechStack";
import LeadWorks from "../components/LeadWorks";
import ToContact from "../components/ToContact";
import Layout from "../components/Layout";

function Home() {
  return (
    <Layout>
      <Hero>
        <div className="row">
          <div className="col-12">
            <h1 className="hero-title">Hi! We are The Bay City.</h1>
            <p className="hero-title">
              Your UX/UI Design &amp; Development partner based in Da Nang
            </p>
          </div>
        </div>
      </Hero>
      <LeadText />
      <LeadServices />
      <LeadTechStack />
      <LeadWorks />
      <ToContact />
    </Layout>
  );
}

export default Home;
