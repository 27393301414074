import React from "react";

function LeadWorks() {
  return (
    <section className="section">
      <div className="section-lead">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-4">
              <h3 className="lead-title anim-bot mb-4 mb-lg-0">
                <span className="text-muted">04.</span>
                <br />
                Recent Projects
              </h3>
            </div>
            <div className="col-12 col-lg-8 mt-0 mt-lg-4">
              <div className="anim-bot lead-work-item">
                <h4 className="lead-subtitle">
                  <a
                    className="highlighted-link"
                    href="https://plotam.com/"
                    target="_blank"
                  >
                    Plotam.com
                  </a>{" "}
                  - real estate platform for Africa.
                </h4>
                <p className="lead-small text-muted">
                  Our role: UX/UI Design, MVP Concept, Frontend and Backend
                  Development, Support and Maintenance.
                </p>
              </div>

              <div className="anim-bot lead-work-item">
                <h4 className="lead-subtitle">
                  <a
                    className="highlighted-link"
                    href="https://gopracti.com/"
                    target="_blank"
                  >
                    GoPracti.com
                  </a>{" "}
                  - online learning solution for aged and disability support
                  providers.
                </h4>
                <p className="lead-small text-muted">
                  Our role: UI Design, Frontend and Backend Development, NextJS
                  integration for better SEO.
                </p>
              </div>

              <div className="anim-bot lead-work-item">
                <h4 className="lead-subtitle">
                  <a
                    className="highlighted-link"
                    href="https://apps.apple.com/us/app/plotam/id1535992117/"
                    target="_blank"
                  >
                    iOS
                  </a>{" "}
                  and{" "}
                  <a
                    className="highlighted-link"
                    href="https://play.google.com/store/apps/details?id=com.plotam.listingapp"
                    target="_blank"
                  >
                    Android
                  </a>{" "}
                  apps for Plotam.com.
                </h4>
                <p className="lead-small text-muted">
                  Our role: UI Design, Frontend and Backend Development,
                  Support.
                </p>
              </div>

              <div className="anim-bot">
                <p className="lead-small text-muted">
                  More cases and stories coming soon...
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LeadWorks;
