import React from "react";

function LeadText() {
  return (
    <section className="section lead-dark text-white">
      <div className="img-anim">
        <div className="img-anim-bg" />
        <img src={`/assets/images/home-cta2.webp`} alt="Da Nang" />
      </div>
      <div className="section-lead">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-4">
              <h3 className="lead-title anim-bot mb-4 mb-lg-0">
                <span className="text-muted">01.</span>
                <br />
                Your Business Partner
              </h3>
            </div>
            <div className="col-12 col-lg-8">
              <div className="lead-content anim-bot">
                <div className="lead-text">
                  The Bay City is a digital team passionate about creating
                  unique digital experiences. We help businesses and startups
                  solve their design problems with focus on user and business
                  goals both.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LeadText;
