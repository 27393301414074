import React, { useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { ThemeContext } from "../context/themeContext";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children, disableAfterLoadingImg }) => {
  const {
    afterLoading,
    afterLoadingImg,
    onScrollGreenAnim,
    onScrollBottomAnim,
  } = useContext(ThemeContext);

  useEffect(() => {
    afterLoading();
    if (!disableAfterLoadingImg) {
      afterLoadingImg();
    }
    const handleScroll = (e) => {
      onScrollBottomAnim(e);
      onScrollGreenAnim(e);
      onScrollGreenAnim(e);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [afterLoading, afterLoadingImg, onScrollBottomAnim, onScrollGreenAnim]);

  return (
    <>
      <motion.div
        initial={{ y: 0 }}
        animate={{ y: "-100%" }}
        exit={{ y: 0 }}
        transition={{ duration: 0.75, ease: [0.43, 0.13, 0.23, 0.96] }}
        className="page-trans"
      />
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
