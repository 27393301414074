import React from "react";
import { Link } from "react-router-dom";

function ToContact() {
  return (
    <section id="toContactSection" className="position-relative vw-py-3">
      <div className="green-bg green-anim" />
      <div className="container-fluid ">
        <div className="row">
          <div className="col-12 to-contact-cta text-center  py-5">
            <h3 className="lead-title text-white anim-bot">
              Have a project in mind? <br /> Let’s plan the next step together!
            </h3>
            <div className="anim-bot mt-4">
              <Link className="lead-btn" to="/contact">
                Get in Touch
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ToContact;
