import React from "react";

function LeadTeam() {
  return (
    <section className="section">
      <div className="img-anim">
        <div className="img-anim-bg" />
        <img
          src={`/assets/images/golden-bridge.webp`}
          alt="Da Nang Golden Bridge"
        />
      </div>
    </section>
  );
}

export default LeadTeam;
